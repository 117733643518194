// Style
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';

// MUI components
import { LoadingButton } from '@mui/lab';
import { Add, Close, DeleteTwoTone, RestartAlt, Undo } from '@mui/icons-material';
import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import ScheduleIcon from '@mui/icons-material/Schedule';
import IconTask from '../../../assets/icon-tasks-blue.svg';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

// React Components
import { v4 as uuid } from 'uuid';
import React, { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, isEmpty, isString, isArray, } from 'lodash';
import { Subscription } from 'rxjs';
import { useForm, useFormState } from 'react-hook-form';
import { PageSize } from '@react-pdf/types';

// TDI Components
import InspectionsSummaryForm from './component/InspectionsSummaryForm';
import HistoryTab from 'src/components/HistoryTab';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import EqSchedPMConfDialog from 'src/components/UI/EqSchedPMConfDialog';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import LogEntryDetailFormOld from '../../PageDrawer/LogEntry/LogEntryDetailFormOld';
import ProcedureDocument from '../EquipmentSchedulesDE/component/ProcedureDocument';
import InspectionReport from 'src/components/UI/PDF/reports/InspectionReportsPDF/inspectionReport';
import { CustomDialog } from 'src/helpers/modals';
import CheckListTab from './CheckListComponent/CheckListTab';
import PrintButtonOld from 'src/components/UI/PrintButton/indexOld';

// Data
import { getDatabase } from '../../../rxdb';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import { LogEntry, TblSchedChk, TblSchedMaintEq } from '../../../generated/graphql';
import {SchedMaintEQDocument} from "../../../rxdb/collections/SchedMaintEQ/schema";
import { TblSchedChkDescDocument } from 'src/rxdb/collections/SchedChkDesc/schema';
import { TblSchedChkDocument } from 'src/rxdb/collections/SchedChk/schema';

// Utils
import { validateForm } from './utils';
import { useAppState } from 'src/contexts/app-state';
import { useAuth } from 'src/contexts/auth';
import useSnackbar from "../../../hooks/common/useSnackbar";
import { copySpareUsedFromLogEntry, copySpareUsedFromWorkissue, equipmentHoursUpdate, filterNonNullStrings, getPlainTextFromHtml, handleCharLimitWarning } from 'src/utils';
import { activateSchedules, deActivateSchedules  } from 'src/pages/EquipmentSchedules/utils';
import { getRecurrencePerSchedMaint } from 'src/utils';
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { getRecurrenceDate } from 'src/utils/Recurrence';
import { getEquipment, getFullLocationPath } from 'src/pages/WorkIssuesPage/utils';
import { CHAR_LIMIT, scheduleDeleteWarningMessage } from 'src/consts';
import AttachmentTab from 'src/modules/Attachments';
import { AttachmentType, RecordType } from 'src/generated/dotnet.graphql';
import Comments from 'src/modules/Comments';

const useStyles: any = makeStyles((theme: any) => ({
  staticTooltipLabel : {
    minWidth: "max-content",
  }
}));

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: any;
  onCancel: () => void;
  onSave: (issue: SchedMaintEQDocument, isCreate: boolean, e?: any) => void;
  onDelete: (issue: any) => void;
  onUndo?: () => void;
  moduleReadOnly: boolean;
  isCreate?: boolean;
  type?: string;
}

export interface InspectionReportType {
  id: string,
  fldSubject: string,
  pageSize?: PageSize,
  checks: ICheckReportType[],
}

export interface ICheckReportType {
  PKey: string;
  EqKey?: string | null;
  fldLocHierarchy?: string | null;
  DescKey: string | null;
  fldIndex?: number | null;
  fldTitle?: string | null;
  equipment?: string | null;
  location?: string | null;
  fldDescriptions?: string | null;
}

const transformData = (
  check: TblSchedChkDocument,
  description?: TblSchedChkDescDocument | null,
  equipmentName?: string,
  locationName?: string,
): ICheckReportType => {
  return {
      PKey: check.PKey,
      EqKey: check?.EqKey,
      fldLocHierarchy: check?.fldLocHierarchy,
      DescKey: check.DescKey || null,
      fldIndex: check?.fldIndex,
      fldTitle: check.fldTitle || null,
      equipment: equipmentName,
      location: locationName,
      fldDescriptions: description && description.fldDescriptions ? getPlainTextFromHtml(description.fldDescriptions) : null,
  };
};

const InspectionsDetailForm: FC<Props> = ({
  initialValue, 
  onCancel, 
  onSave, 
  onDelete, 
  onUndo, 
  moduleReadOnly = false, 
  isCreate = false, 
  type, 
  setFormIsDirty
}) => { 
 
  const { control, setValue, handleSubmit, getValues, reset, watch, formState } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      PKey: initialValue.PKey,
      fldSubject: initialValue.fldSubject || "",
      DateEntered: normalizeDateFormValue(initialValue.DateEntered),
      // EqKey: initialValue.EqKey,
      SchedKey: initialValue.SchedKey,
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      Department: initialValue.Department,
      fldListType: initialValue.fldListType,
      fldHTML: initialValue.fldHTML,
      deletedAt: initialValue.deletedAt,
      deletedBy: initialValue.deletedBy,
      fldDateTrigger: normalizeDateFormValue(initialValue?.fldDateTrigger),
      fldDeferred: initialValue.fldDeferred,
      fldDeferredDate: normalizeDateFormValue(initialValue?.fldDeferredDate),
      fldHoursCompleted: initialValue.fldHoursCompleted,
      fldHoursTrigger: initialValue.fldHoursTrigger || null,
      fldIndex: initialValue.fldIndex || null,
      fldIterations: initialValue.fldIterations > 0 ? initialValue.fldIterations : null,
      fldRunOnce: initialValue.fldRunOnce,
      isRecoverable: initialValue.isRecoverable,
      updatedAt: initialValue.updatedAt,
      scheduleType: initialValue.scheduleType,
      simpleCheck: initialValue.simpleCheck || false,
      fldTimeWarn: initialValue.fldTimeWarn || "",
      RecurPattern: initialValue.RecurPattern ? initialValue.RecurPattern.split(','): null,
      fldHourWarn: initialValue.fldHourWarn || null,
      fldTimeMeasureWarn: initialValue.fldTimeMeasureWarn || null,
      fldHourLock: initialValue.fldHourLock || false,
      fldCSM: initialValue.fldCSM || false,
      fldSMS: initialValue.fldSMS || false,
      fldUserDefined: initialValue.fldUserDefined || null,
      fldIsCheckList: initialValue.fldIsCheckList || false,
      fldLinkID: initialValue.fldLinkID || null,
      fldAssignedTo: isEmpty(initialValue.fldAssignedTo) ? [] : isString(initialValue.fldAssignedTo) ? initialValue.fldAssignedTo?.split(', ') : initialValue.fldAssignedTo,
      fldHourInterval: initialValue.fldHourInterval || '',
      fldSteps: initialValue.fldSteps || '',
      RecurPatternEveryMonthOrdinal: "",
      RecurPatternEveryMonthDay: "",
      RecurPatternEveryMonth: "",
      RecurPatternEveryYearOrdinal: "",
      RecurPatternEveryYearDay: "",
      RecurPatternEveryYearMonth: "",
      RecurType: initialValue.RecurType,
    },
  });

  const { showSnackbar } = useSnackbar();
  const [oldIssuesUndo, setOldIssuesUndo] = useState<TblSchedMaintEq[]>([]);
  const [checksRawData, setChecksRawData] = useState<TblSchedChkDocument[]>([]);
  const [checksData, setChecksData] = useState<ICheckReportType[]>([]);
  const [inspection, setInspection] = useState<any>(initialValue);
  const [workIssueCount, setWorkIssueCount] = useState<number>(0);
  const [eqSchedPickedValue, setEqSchedPickedValue] = useState<string>();
  const [checkItemsCount, setCheckItemsCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const forminitialValue = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEqSchedConfirming, setIsEqSchedConfirming] = useState(false);
  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const [triggerTaskCreated, setTriggerTaskCreated] = useState<any>(null);
  const [showTriggerTaskPopup, setShowTriggerTaskPopup] = useState<boolean>(false);
  const [isValidCheckOnActivation, setIsValidCheckOnActivation] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showLogEntryPopup, setShowLogEntryPopup] = useState<boolean>(false);
  const [logEntry, setLogEntry] = useState<LogEntryDocument>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [showChecksValidationPopup, setShowChecksValidationPopup] = useState<boolean>(false);
  const [saveData, setSaveData] = useState<any>();
  const { TBLSCHEDMAINT } = CHAR_LIMIT;

  const [recordReadOnly, setRecordReadOnly] = useState(false);
  const [historyCount, setHistoryCount] = useState<number>(0);
  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();

  const setRecordReadOnlyPermission = async ()=>{
    if(settingsPersonal.fldAllDepts != 2 && user?.Department != initialValue.Department){
      setRecordReadOnly(true)
    }
    if(!settingsPersonal.fldDeptHead) {
      setRecordReadOnly(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setRecordReadOnlyPermission();
    }
  },[settingsPersonal])

  const handleEqSchedOptionChange = (event: any) => {
    setEqSchedPickedValue(event.target.value);
  };

  const getDocumentCount = async () => {
    const db = await getDatabase();
    // Find and count attachments
    activeSubscriptions.current = [
      db.tblschedchk
        .find({
          selector: {
            FKey: isNil(initialValue.PKey) ? '' : initialValue.PKey,
            Completed: {
              $eq: false,
            },
            deletedAt: {
              $eq: null
            },
          }
        })
        .$.subscribe((c) => {
          setCheckItemsCount(size(c));
        }),
    ];

    db.workissues.$.subscribe(async (ev) => {
      loadWorkIssueCount();
    });
  };

  const setInitialValue = async () => {
    let defaultValues = {
      ...getValues(),
    };
    if(getValues('fldHoursTrigger') != initialValue.fldHoursTrigger){
      defaultValues = {
        ...defaultValues,
        fldHoursTrigger: initialValue.fldHoursTrigger || null,
      }
    }
    forminitialValue.current = defaultValues;
    reset(defaultValues);
  };

  const setLogEntryData = async () => {
    const db = await getDatabase();

    const department = await db.tblddlistdefaults
      .findOne({
        selector: {
          fldListName: 'Department',
          fldMember: user?.Department,
        },
      })
      .exec();

    const document: LogEntryDocument = await db.logentry.newDocument({
      PKey: uuid(),
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      MaintKey: initialValue.PKey,
      DayLogType: 2,
      fldIsCheckList: false,
      LogDate: new Date().toISOString(),
      fldPerformedBy: user ? `${user.fldFirst} ${user.fldLast}` : null,
      fldWorkList: initialValue.fldListType,
      Department: department?.fldMember,
      LogEntry: initialValue.fldSubject,
    });
    setLogEntry(document);
  }

  const loadWorkIssueCount = async () => {
    const db = await getDatabase();
    const workissue = await db.workissues.find({
      selector: {
        fldSchedMaintKey: {
          $eq: initialValue.PKey,
        },
        deletedBy:{
          $eq: null,
        },
        Completed:false,
      }
    }).exec();        
    if(workissue)
      setWorkIssueCount(workissue.length)
  }

  const getSchedChecks = async () => {
    const db = await getDatabase();
    db.tblschedchk.find({
      selector: {
        FKey: {
          $eq: initialValue.PKey,
        },
        deletedBy:{
          $eq: null,
        },
        Completed:false,
      }
    }).$.subscribe((checks) => {
      setChecksRawData(checks);
      getChecksDetails(checks);
    })} 

  const getChecksDetails = async (checksList: any[]) => {
    const data: ICheckReportType[] = [];
    for (const check of checksList) {
      const db = await getDatabase();
      const description = await db.tblschedchkdesc
        .findOne({
          selector: {
            PKey: check.DescKey,
          },
        })
        .exec();
      const equipment = await getEquipment(check);
      const location = await getFullLocationPath(check);
  
      const transformedData = transformData(
        check,
        description,
        equipment?.UniqueName,
        location
      );
      data.push(transformedData);
    }

    data.sort((a, b) => (a.fldIndex || 0) - (b.fldIndex || 0));
    setChecksData(data);
  };

  useEffect(() => {
    getSchedChecks();
    getDocumentCount();
    setInitialValue();
    setLogEntryData();
    loadWorkIssueCount();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      forminitialValue.current = {};
    };
  }, [initialValue]);

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const handleCancel = () => {
    setOldIssuesUndo([]);
    onCancel();
  };

  const extractContent = (s: string) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const checkIfWorkIssueNeedsToBeUpdated = async (update: boolean = false) => {
    if (!isCreate && (update || formState.dirtyFields.fldDateTrigger || formState.dirtyFields.fldHoursTrigger)) {
      const db = await getDatabase();
      const workissue = await db.workissues.find({
        selector: {
          fldSchedMaintKey: {
            $eq: initialValue.PKey,
          },
          deletedBy: {
            $eq: null
          },
          Completed:false,
        }
      }).exec();
      if (workissue && workissue.length > 0) {
        setIsEqSchedConfirming(true);
      }
    }
  }

  const onContinueAnyWay = async () => {
    setShowChecksValidationPopup(false);
    saveData.fldDeferred = true;
    handleSave(saveData);
  }

  const onSaveClick = async (data: any)=>{
    setProcessing(true);
    if (checkItemsCount === 0 && !data.fldDeferred) {
      setShowChecksValidationPopup(true)
      setSaveData(data)
    } else {
      handleSave(data)
    }
  }

  const onCancelChecksValidationPopup = () => {
    setShowChecksValidationPopup(false)
    setSaveData(null)
    setProcessing(false)
    setFormIsDirty && setFormIsDirty(false) // clear form on cancel
  }

  const handleSave = async (data: any, e?: any) => {
    setProcessing(true);
    if (!validateForm(data, showSnackbar)) return;
    const db = await getDatabase();

    let {
      fldSubject,
      fldDateTrigger,
      fldDeferredDate,
      fldIterations,
      fldSRHKey,
      fldLocHierarchy,
      fldTimeWarn,
      fldHTML,
      fldHourInterval,
      fldTimeMeasureWarn,
      fldAssignedTo,
      Department,
      fldListType,
      fldCSM,
      fldSMS,
      RecurType,
      fldUserDefined,
      fldIsCheckList,
      fldHoursTrigger,
      fldHoursCompleted,
      fldRunOnce,
      fldSteps,
      fldDeferred,
      DateEntered,
      fldIndex,
    } = data;

    if (DateEntered) {
      DateEntered = normalizeDateTime(DateEntered);
    }

    let recurPattern = ''
    //set up recurPattern
    switch (data.RecurType) {
      case "47":{
        recurPattern = data.RecurPatternWeeklyRegenerate?.toString()
        break;
      }
      case "48":{
        const sortedWeekDays = data.weekDays.sort()
        await sortedWeekDays.unshift(`${data.RecurPatternWeeklyEveryDay}`)
        recurPattern = sortedWeekDays.join(',')
        break;
      }
      case "49":{
        recurPattern = data.RecurPatternRegenerate?.toString();
        break;
      }
      case "50":{
        recurPattern = [data.RecurPatternEveryYearOrdinal?.id, data.RecurPatternEveryYearDay?.id, data.RecurPatternEveryYearMonth?.id].join(",");
        break;
      }
      case "51":{
        recurPattern = [data.RecurPatternEveryMonth?.id, data.RecurPatternEveryMonthDay].join(",");
        break;
      }
      case "54":{
        recurPattern = data.RecurPatternRegenerate?.toString();
        break;
      }
      case "55":{
        recurPattern = [data.RecurPatternEveryMonthOrdinal?.id, data.RecurPatternEveryMonthDay?.id, data.RecurPatternEveryMonthMonths].join(",");
        break;
      }
      case "56":{
        recurPattern = [data.RecurPatternEveryDay, data.RecurPatternEveryMonth].join(",");
        break;
      }
      case "62":{
        recurPattern = data.RecurPatternRegenerate?.toString();
        break;
      }
      case "63":{
        recurPattern = "2,3,4,5,6";
        break;
      }
      case "64":{
        recurPattern = data.RecurPatternEvery?.toString();
        break;
      }
      case "100":{
        recurPattern = "";
        break;
      }
      default:{
        recurPattern = isNil(initialValue.RecurPattern)? null : initialValue.RecurPattern
        break;
      }
    }

    const schedMaintDocument = {
      PKey: initialValue.SchedKey,
      fldSubject,
      fldNotes: extractContent(fldHTML) || null,
      fldHoursKey: null,
      fldHourInterval: parseInt(fldHourInterval, 10) || null,
      fldTimeInterval: null,
      fldTimeMeasure: null,
      fldTimeWarn: parseInt(fldTimeWarn, 10) || null,
      fldTimeMeasureWarn: fldTimeMeasureWarn?.timeMeasure || null,
      fldSteps,
      RecurType: parseInt(RecurType, 10) || 0,
      fldAssignedTo:fldAssignedTo?.join(', ') || null,
      Department: isString(Department) ? Department : Department?.fldMember || null,
      RecurInterval: null,
      RecurPattern: recurPattern,
      fldToolsReqd: null,
      fldTTLWork: null,
      fldCSM,
      fldSMS,
      fldSchedType: initialValue.fldSchedType,
      fldUserDefined: isString(fldUserDefined) ? fldUserDefined : fldUserDefined?.fldMember || null,
      fldListType: isString(fldListType) ? fldListType : fldListType?.fldMember || null,
      fldDuration : initialValue.fldDuration || null,
      DateEntered: null,
      fldIsCheckList: fldIsCheckList === true ? fldIsCheckList: false,
      fldQuotedPrice : null,
      fldQuotedCurrency: null,
      fldHTML,
      fldRTF: null,
      fldPlainText: extractContent(fldHTML) || null,
      updatedAt:new Date().toISOString(),
    } as any

    const iterationCount = (isNil(fldIterations) || fldIterations <= 0) ? -1 : fldIterations;
  
    const schedMaintEQDocument = {
      PKey: initialValue.PKey,
      SchedKey : initialValue.SchedKey,
      fldHoursTrigger: parseInt(fldHoursTrigger, 10) || null,
      fldHoursCompleted,
      fldDateTrigger: normalizeDateTime(fldDateTrigger),
      fldRunOnce,
      fldIterations: parseInt(iterationCount),
      fldDeferred: fldDeferred,
      DateEntered,
      fldIndex,
      fldDeferredDate,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      fldLocHierarchy: (isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy) || null,
      updatedAt:new Date().toISOString(),
    } as any;

    try {
      setOldIssuesUndo([]);
      const resMaint = await db.collections.tblschedmaint.upsert(schedMaintDocument);
      const res = await db.collections.tblschedmainteq.upsert(schedMaintEQDocument);

      const updatedData={
        ...data,
        original: initialValue.original,
        RecurPattern: recurPattern,
        RecurType: parseInt(RecurType, 10),
      } as any

      onSave(updatedData, isCreate, e);
      setProcessing(false)
      const formVal = getValues();
      reset(formVal);
      showSnackbar({
        type: 'success',
        message: isCreate ? 'New Inspection has been created!' : 'Inspection has been updated!',
      })

      await checkIfWorkIssueNeedsToBeUpdated();
      onCancel(); // close drawer after creating new inspection
    } catch (e: any) {

      showSnackbar({
        type: 'error',
        message: e.message,
      });
    }
  };

  const handleDelete = () => {
    setIsDeleting(true);
  };

  const handleDeleteOk = () => {
    onDelete(initialValue.original);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  };

  const handleEqSchedDialogOk = async () => {
    const db = await getDatabase();
    const workissue = await db.workissues.find({
      selector: {
        fldSchedMaintKey: {
          $eq: initialValue.PKey,
        },
        deletedBy: {
          $eq: null
        },
        Completed:false,
      }
    }).exec();
    if(workissue && workissue.length > 0) {
      const workIssueItem = workissue[0];
      if(eqSchedPickedValue === 'change') {
        workIssueItem.atomicPatch({
          DateDue: normalizeDateTime(getValues('fldDateTrigger'))
        })
        console.log('workissue updated', workIssueItem);
        showSnackbar({
          message: `Workissue ${workIssueItem.Subject} updated sucessfully`,
          type: 'success'
        });
      } else if(eqSchedPickedValue === 'remove') {
        workIssueItem.atomicPatch({
          Completed: true,
          deletedAt: new Date().toISOString(),
          deletedBy: user?.fldCrewID,
          isRecoverable: false
        });
        showSnackbar({
          message: `Workissue item "${workIssueItem.Subject}" deleted permanently`,
          type: 'success'
        });
      }
    }
    setIsEqSchedConfirming(false);
  };

  const handleEqSchedDialogCancel = () => {
    setIsEqSchedConfirming(false);
  };

  const handleUndo = () => {
    const issue = last(oldIssuesUndo);
    setInspection(issue as TblSchedMaintEq);
    // Remove last step from our store
    setOldIssuesUndo(oldIssuesUndo.splice(-1));
    onUndo && onUndo();
  };

  const handleCancelUndo = () => {
    if (isCreate) {
      return onCancel();
    }
    reset();
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), showSnackbar) && !recordReadOnly) return;
    if (isEditing && !recordReadOnly) return; // We will send submit action that will be handled in HandleSave.
    handleCancel();
  };

  const handleActivationCheck = () => {
    setIsValidCheckOnActivation(false)
  }

  const activateDeactivateEventSched = async () => {
    // const db = await getDatabase();
    const initialDefferedValue = initialValue.fldDeferred;

    try {
      if(checkItemsCount === 0) {
        setIsValidCheckOnActivation(true);
        return;
      }

      initialValue.fldDeferred ? await activateSchedules([{...initialValue}]): await deActivateSchedules([{...initialValue}]) ;
      initialValue.fldDeferred = !initialDefferedValue;

      showSnackbar({
        type: 'success',
        message: initialDefferedValue ? 'Inspection activated successfully' : 'Inspection deactivated successfully'
      });

      await checkIfWorkIssueNeedsToBeUpdated(true);
    } catch (e: any) {

      showSnackbar({
        type: 'error',
        message: initialDefferedValue ? `Inspection activation error - ${e.message}` : `Inspection deactivation error - ${e.message}`,
      });
    }
  }

  const triggerTask = async () => {
    handleClose();

    if(checkItemsCount === 0) {
      setTriggerTaskCreated(true);
      setShowTriggerTaskPopup(true);
      return;
    }

    const db = await getDatabase();

    if(initialValue.fldDeferred){
      setTriggerTaskCreated(true);
      setShowTriggerTaskPopup(true);
      return;
    }

    const workissue = await db.workissues.find({
      selector: {
        fldSchedMaintKey: {
          $eq: initialValue.PKey,
        },
        deletedBy:{
          $eq: null,
        },
        Completed:false,
      }
    }).exec();
    
    if(workissue && workissue.length > 0) {
      setTriggerTaskCreated(true);
      setShowTriggerTaskPopup(true);
      return;
    }
    
    // start - colect checks and save them to [Checks] field
    const checksList: TblSchedChk[] = [];

    checksRawData.map(check => {
      checksList.push(check.toJSON());
    })
    
    const checksToString = JSON.stringify(checksList);
    // end
    
    const document = {
      JobNumber: uuid(),
      Subject: initialValue.fldSubject,
      fldSchedMaintKey: initialValue.PKey,
      fldSMSType: "CHKL",
      fldSMSID: null,
      fldSMS: initialValue.fldSMS || initialValue?.fldSMS,
      fldDeptCreator: user?.Department,
      Department: initialValue.Department,
      DateStart: normalizeDateTime(initialValue.fldDateTrigger),
      DateDue: normalizeDateTime(initialValue.fldDateTrigger),
      AssignedTo: isArray(initialValue.fldAssignedTo) ? initialValue.fldAssignedTo?.join(','): initialValue.fldAssignedTo,
      fldIsCheckList: false,
      fldHTML: initialValue.fldHTML,
      fldSRHKey: initialValue.fldSRHKey, //?????
      updatedAt: new Date().toISOString(),
      fldIsWarranty: false,
      fldWorkList: initialValue.fldListType,
      fldIsCAR: false,
      Checks: checksToString,
    } as any;

    try {
      await db.workissues.upsert(document);
      copySpareUsedFromWorkissue(initialValue.SchedKey, document);

      setTriggerTaskCreated(false);
      setShowTriggerTaskPopup(true);
    }
    catch (e: any) {
      showSnackbar({
        message: e.message,
        type: 'error'
      });
    }
  }

  const handleCancelLogEntry = () => {
    setShowLogEntryPopup(false);
    setProcessing(false);
  }

  const handleSaveLogEntry = async (logEntry: LogEntry) => {
    const db = await getDatabase();
    const maintEq = await db.tblschedmainteq
      .findOne({
        selector: {
          PKey: initialValue.PKey,
        },
      })
      .exec();

    if (!isNil(maintEq)) {
      // MaintEq shouldn't null since we have preSave check.
      const recurrence = await getRecurrencePerSchedMaint(maintEq, logEntry as LogEntryDocument);
      if (recurrence && !isNil(maintEq?.EqKey) && !isNil(logEntry.fldHours)) {
        await equipmentHoursUpdate(maintEq?.EqKey, logEntry.fldHours);
      }
      
      // advance the date trigger
      let nextDueDate: Date | undefined = getRecurrenceDate(initialValue.RecurType, initialValue.RecurPattern, 
        new Date(logEntry.LogDate), maintEq.fldDateTrigger);
      await maintEq?.atomicPatch({
        ...recurrence,
        fldDateTrigger: normalizeDateTime(nextDueDate),
      });

      copySpareUsedFromLogEntry(initialValue.SchedKey, logEntry as LogEntryDocument);

      const updatedData={
        ...initialValue,
        original: initialValue.original,
        ...recurrence,
        fldDateTrigger: normalizeDateTime(nextDueDate),
      } as any;
      onSave(updatedData, isCreate);
    }

    handleCancelLogEntry();
    showSnackbar({
      message: 'New log entry has been created!',
      type: 'success',
    });
  }

  const handleGeneratePDF = () => {
    const inspectionData: InspectionReportType = {
      id: initialValue.PKey,
      fldSubject: initialValue.fldSubject,
      checks: checksData,
    };
    return InspectionReport(inspectionData);
  };

  const actions = [
    {
      icon: <img src={IconTask} alt="" />,
      name: 'Trigger Task',
      onclick: triggerTask,
      disabled: workIssueCount > 0
    },
    {
      icon: <IconButton><ScheduleIcon color={ initialValue.fldDeferred  ? 'success': 'error'}/></IconButton>,
      name: initialValue.fldDeferred ? 'Activate Inspection': 'Suspend Inspection',
      onclick: activateDeactivateEventSched,
      disabled: workIssueCount > 0
    },
    {
      icon: <PrintButtonOld generatePDF={handleGeneratePDF} enable={true} title='Print Inspection Report' />,
      name: 'Print Inspection Report',
      onclick: undefined, // PrintButton component is handling the onClick functionality
      disabled: checksData.length === 0, // wait for checks details before triggering the report
    },
  ];

  if (isNil(inspection)) return null;

  const hasValuesBeenChanged =
    formState.isDirty &&
    (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreate;
  
  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const classes = useStyles();

  const formClass = type === 'Dialog'
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <>
      <form
        id="InspectionsDetailForm"
        className={`${formClass}`}
        onSubmit={handleSubmit(onSaveClick)}
      >
        <div className="bg-white h-full flex-grow pt-6">
          <div className="px-6 h-full flex flex-col">
            <div className="mb-6">
              {(moduleReadOnly || recordReadOnly) && (
                <RecordEditWarningCard />
              )}
              <div className="mui-textfield-header mb-2">
                <Input
                  inputProps={{
                    size: 'medium',
                    label: 'Inspection Title',
                    variant: 'standard',
                  }}
                  rules={{ required: true, maxLength: TBLSCHEDMAINT.fldSubject }}
                  warning={(value) => handleCharLimitWarning(value, TBLSCHEDMAINT.fldSubject)}
                  control={control}
                  name="fldSubject"
                />
              </div>
              {!recordReadOnly && (
                <div className="mb-2">
                  <ProcedureDocument
                    initialValue={initialValue}
                    recordType={RecordType.InspectionSchedule}
                  />
                </div>
              )}
            </div>

            <div>
              {
                <Tabs
                  tabs={[
                    {
                      label: 'Summary',
                      component: (
                        <InspectionsSummaryForm
                          watch={watch}
                          control={control}
                          initialValue={initialValue}
                          form={inspection}
                          onChange={onChange}
                          setValue={setValue}
                          getValues={getValues}
                          disableEdit={recordReadOnly}
                        />
                      ),
                    },
                    {
                      label: `Check List (${checkItemsCount})`,
                      component: (
                        <CheckListTab
                          watch={watch}
                          type="checkList"
                          initialValue={initialValue}
                          SRHKey={initialValue.fldSRHKey}
                          onChange={onChange}
                          tableName="TblSchedMaintEq"
                          disabled={moduleReadOnly} 
                          disableEdit={recordReadOnly}
                        />
                      )
                    },
                    {
                      // label: `History (${historyCount})`, // TODO - temporary hide the count until refactoring Equipment mudule with the .net api
                      label: `History`,
                      component: (
                        <HistoryTab 
                          keyName='scheduleId' 
                          keyValue={initialValue.PKey} 
                          setHistoryCount={setHistoryCount}
                          countHours={false}
                          recordReadOnly={recordReadOnly} 
                        />
                      ),
                    },
                    {
                      // label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                      label: `Attachments`,
                      disabled: isCreate,
                      component: (
                        <AttachmentTab
                          recordId={initialValue.PKey}
                          recordType={RecordType.InspectionSchedule}
                          recordTypeName={'InspectionSchedule'} // when refactor to .net api, add proper object.__typename for counts cache update
                          attachmentType={AttachmentType.Document}
                          categoryId={initialValue.fldSRHKey}
                          setAttachmentsCount={setDocumentsCount}
                          readOnly={moduleReadOnly || recordReadOnly} 
                        />
                      ),
                    },
                    {
                      // label: `Photos (${photosCount})`,
                      label: `Photos`,
                      disabled: isCreate,
                      component: (
                        <AttachmentTab
                          recordId={initialValue.PKey}
                          recordType={RecordType.InspectionSchedule}
                          recordTypeName={'InspectionSchedule'} // when refactor to .net api, add proper object.__typename for counts cache update
                          attachmentType={AttachmentType.Photo}
                          categoryId={initialValue.fldSRHKey}
                          setAttachmentsCount={setPhotosCount}
                          readOnly={moduleReadOnly || recordReadOnly} 
                        />
                      ),
                    },
                    {
                      // label: `Comments (${commentsCount})`,
                      label: `Comments`,
                      disabled: isCreate,
                      component: (
                        <Comments
                          recordId={initialValue.PKey}
                          recordType={RecordType.InspectionSchedule}
                          recordTypeName={'InspectionSchedule'} // when refactor to .net api, add proper object.__typename for counts cache update
                          setCommentsCount={setCommentsCount}
                          readOnly={recordReadOnly}
                        />
                      ),
                    },
                  ]}
                />
              }
            </div>
          </div>
        </div>
        {type !== 'Dialog' && (
          <StickyAppBar
            cancelText="Cancel"
            okType={isEditing ? 'submit' : 'button'}
            okText={isEditing ? 'Save' : 'Close'}
            onOk={() => handleOk(isEditing)}
            onCancel={isEditing ? () => handleCancelUndo() : undefined}
            disabled={(moduleReadOnly || recordReadOnly)  && isEditing}
          >
            {!isCreate && !recordReadOnly && !moduleReadOnly && !isNil(inspection.PKey) && (
              <Box sx={{ position: 'relative', height: 70 }}>
                <Backdrop open={open} />
                <SpeedDial
                    ariaLabel="SpeedDial tooltip example"
                    sx={{ position: 'absolute', bottom: 12, right: 12 }}
                    FabProps={{ size: 'small' }}
                    icon={
                      <SpeedDialIcon
                        sx={{ fontSize: 'small' }}
                        icon={<SpeedDialIcon />}
                        openIcon={<Close />}
                      />
                    }
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      classes={classes}
                      onClick={() => {if(!action.disabled && action.onclick) action.onclick()}}
                      FabProps= {{disabled: action.disabled}}
                    />
                  ))}
                </SpeedDial>
              </Box>
            )}
            {!isEmpty(oldIssuesUndo) && (
              <IconButton
                onClick={handleUndo}
                color="inherit"
                aria-label="Undo last step"
              >
                <Undo />
              </IconButton>
            )}
            {!isCreate && !moduleReadOnly && !recordReadOnly && !isNil(inspection.PKey) && (
              <IconButton
                onClick={handleDelete}
                color="error"
                aria-label="Delete task"
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </StickyAppBar>
        )}
      </form>

      <Dialog
        open={showChecksValidationPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{(<WarningTwoToneIcon fontSize="small" color="warning" className="mr-3"/>)}{'Suspend inspection?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to suspend this schedule.  Inspection does not contain any checks.  Would you like to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'flex-end' }}>
          <Button onClick={onCancelChecksValidationPopup}>Cancel</Button>
          <Button onClick={onContinueAnyWay} variant='contained'>Continue</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showTriggerTaskPopup}
        onClose={() => setShowTriggerTaskPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="warning-dialog"
      >
        {triggerTaskCreated ? (
          <>
            <DialogTitle id="alert-dialog-title">{(<WarningTwoToneIcon fontSize="small" color="warning" className="mr-3"/>)}{'No task was created for this inspection item.'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div>
                  <div className="ml-3">
                    <span className="mr-3">Please ensure:</span>
                  </div>
                  <div className="ml-3">
                    {<ul>
                      <li>1. Inspection has at least one valid check item created.</li>
                      <li>2. The item is not currently outstanding on your work list.</li>
                      <li>3. Inspection is not suspended.</li>
                    </ul>}
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            </>
          ) : (
            <DialogContent dividers>
              <Typography>
                A task was successfully created for this inspection item.
              </Typography>
            </DialogContent>
          )}
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'flex-end' }}>
          <Button
            autoFocus
            onClick={() => setShowTriggerTaskPopup(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

        {logEntry &&
          <CustomDialog 
            scroll="paper" 
            fullWidth 
            maxWidth="md" 
            open={showLogEntryPopup}
          >
            <DialogTitle>
              <span className="font-bold text-2xl">Log and Reset Inspection</span>
            </DialogTitle>

            <DialogContent dividers sx={{ p: 0 }}>
              <LogEntryDetailFormOld
                initialValue={logEntry}
                onCancel={handleCancelLogEntry}
                onSave={handleSaveLogEntry}
                onDelete={() => { }}
                editFlag={false}
                isCreated={true}
                isLogAndReset={true}
                type='Dialog'
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'flex-end' }}>
              <LoadingButton
                loading={processing}
                type="submit"
                form="logentry-details-form"
                variant="contained"
              >
                Save
              </LoadingButton>

              <Button
                autoFocus
                onClick={() => setShowLogEntryPopup(false)}
              >
                Close
              </Button>
            </DialogActions>
          </CustomDialog>
        }
        <WarningDialog
          visible={isValidCheckOnActivation}
          title="Warning: Cannot Activate Inspection."
          content="Please add at least one Check to Activate Inspection."
          cancelText="Close"
          color="warning"
          onOk={handleActivationCheck}
          disabled={true}
          onCancel={handleActivationCheck}
        />
        <WarningDialog
          visible={isDeleting}
          title="Delete Warning"
          content={scheduleDeleteWarningMessage}
          okText="Yes"
          color="error"
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />
        <EqSchedPMConfDialog
          visible={isEqSchedConfirming}
          title="Update Work List"
          onChange={handleEqSchedOptionChange}
          okText="Apply"
          color="error"
          onOk={handleEqSchedDialogOk}
          onCancel={handleEqSchedDialogCancel}
        />
    </>
  )
};

export default InspectionsDetailForm;
