// This group of imports should be in every data detail component
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import React, { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, omit, isString, isArray, isEmpty } from 'lodash';
// Third party components go here
// check here for @mui/icon-materials values https://mui.com/components/material-icons/
import { DeleteTwoTone } from '@mui/icons-material';
import { Icon, Portal, Alert, IconButton, Snackbar } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CloseIcon from '@mui/icons-material/Close';
import { Subscription } from 'rxjs';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import { RxDocument } from 'rxdb';
import { EquipmentDocMethods, EquipmentDocType } from 'src/pages/EquipmentPage/rxdb';
import { InvBarCodesDocument } from 'src/rxdb/collections/InvBarCodes/schema';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAuth } from 'src/contexts/auth';
import { useAppState } from 'src/contexts/app-state';
import { handleCharLimitWarning, removeNonAlphanumericChars } from 'src/utils';

// images
import IconBarCode from '../../../assets/icon-bc-primary.svg';
import IconAddBarCode from '../../../assets/icon-bc-add.svg';
import IconRemoveBarCode from '../../../assets/icon-bc-remove.svg';
import IconPrintBarCode from '../../../assets/icon-bc-print.svg';
import CartIcon from 'src/assets/icon-cart.svg';

// Reusable module components go here
import { getDatabase } from '../../../rxdb';
import { CHAR_LIMIT } from '../../../consts';

// All module specific components go here
import { Inventory } from '../../../generated/graphql';
import InvGenSummaryForm from './component/InvGenSummaryForm';
import { validateForm } from './utils';
import InvSpares from './component/InvSpares';
import InvOrderHistory from './component/InvOrderHistory';
import BarCode, { BarcodeActionType } from './component/BarCodes/BarCode';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import BarcodePopup from 'src/components/UI/PDF/reports/BarcodePrintButton/BarcodePopup';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import InvOrderItemsAddDialog from './component/InvOrderItemsAddDialog';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';
import AttachmentTab from 'src/modules/Attachments';
import { AttachmentType, RecordType } from 'src/generated/dotnet.graphql';
import Comments from 'src/modules/Comments';

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
    maxWidth: 900,
  },
}));

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: InventoryDocument;
  onCancel: () => void;
  onSave: (invItem: Inventory, isCreated: boolean) => void;
  onDelete: (invitem: InventoryDocument) => void;
  onUndo?: () => void;
  moduleReadOnly: boolean;
  type?: string;
  isCreate: boolean;
  storageUpdate: () => void;
  selectedItemOrderID?: string;
}

const InventoryDetailForm: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  moduleReadOnly = false,
  isCreate,
  type,
  storageUpdate,
  selectedItemOrderID = "",
  setFormIsDirty
}) => {
  const classes = useStyles();
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      fldSRHKey: initialValue.fldSRHKey,
      ProductName: initialValue.ProductName || '',
      Manufacturer: initialValue.Manufacturer,
      ModelNumber: initialValue.ModelNumber || '',
      fldPartNumber: initialValue.fldPartNumber || '',
      Department: initialValue?.Department || null,
      fldSMS: initialValue.fldSMS || false,
      fldConsumable: initialValue.fldConsumable || false,
      Bonded: initialValue.Bonded || false,
      ProductDescription: initialValue.ProductDescription || '',
      ReOrderLevel: initialValue.ReOrderLevel,
      Qty: initialValue.Qty || null,
      fldOrderQty: initialValue.fldOrderQty || null,
      fldFactor: initialValue.fldFactor || null,
      ReOrderAmt: initialValue.ReOrderAmt,
      fldDataType: initialValue.fldDataType,
      ProductID: initialValue.ProductID,
    },
  });

  // const oldInvState = useRef<Inventory>(initialValue.toJSON());

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const [oldInvUndo, setoldInvUndo] = useState<Inventory[]>([]);
  const [invItem, setInvItem] = useState<Inventory>(initialValue.toJSON ? initialValue.toJSON() : initialValue);
  const [spareCount, setSpareCount] = useState<number>(0);
  const [orderHistoryCount, setOrderHistoryCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [removePopupVisible, setRemovePopupVisible] = useState<boolean>(false);
  const [printPopupVisible, setPrintPopupVisible] = useState<boolean>(false);
  const [addOrderItemPopupVisible, setAddOrderItemPopupVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { INVENTORY } = CHAR_LIMIT;

  const [recordReadOnly, setRecordReadOnly] = useState(false);
  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();

  const setRecordReadOnlyPermission = async ()=>{
    if(settingsPersonal.fldAllDepts != 2 && user?.Department != initialValue.Department){
      setRecordReadOnly(true)
    }
    if(!settingsPersonal.fldDeptHead) {
      setRecordReadOnly(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setRecordReadOnlyPermission()
    }
  },[settingsPersonal])

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const getDocumentCount = async () => {
    const db = await getDatabase();

    // Find and count attachments
    activeSubscriptions.current = [
      db.inveq
        .find({
          selector: {
            ProductID: initialValue.ProductID,
            deletedAt: {
              $eq: null
            },
          },
        })
        .$.subscribe(async (equipmentItems) => {
          const results = await Promise.all<RxDocument<
            EquipmentDocType,
            EquipmentDocMethods
          > | null>(
            equipmentItems.map(async (eqItem) => {
              const revision = await db.equipment
                .findOne({
                  selector: {
                    EqKey: eqItem.EqKey,
                  },
                })
                .exec();
              return revision;
            }),
          );
          setSpareCount(size(results));
        }),

      db.orderitems
        .find({
          selector: {
            ProductID: initialValue.ProductID,
          },
        })
        .$.subscribe((c) => {
          setOrderHistoryCount(size(c));
        }),
    ];
  };
  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    getDocumentCount();
    setInitialValues();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;
    if (name === "Manufacturer") {
      setValue(name, { DisplayMember: value.DisplayMember }, { shouldDirty: shouldDirty })
      return;
    }

    if (name === 'fldSRHKey') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.fldSRHKey === updatedValue) {
        shouldDirty = false;
      }
    }

    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    setoldInvUndo([]);
    onCancel();
  };

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar)) return;
    const db = await getDatabase();

    // Create items before creating Item.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ['inputValue', 'isCreate', 'collection']),
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      ProductName,
      fldSRHKey,
      Manufacturer,
      ModelNumber,
      fldPartNumber,
      Department,
      fldSMS,
      fldConsumable,
      Bonded,
      ProductDescription,
      ReOrderLevel,
      Qty,
      fldOrderQty,
      fldFactor,
      ReOrderAmt,
    } = data;

    const QtyValue = isString(Qty)
      ? Qty
      : Qty?.fldMember || null;

    const fldOrderQtyValue = isString(fldOrderQty)
      ? fldOrderQty
      : fldOrderQty?.fldMember || null

    const document = {
      ...invItem,
      ProductName,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      Manufacturer:
        typeof Manufacturer === 'object' ? Manufacturer?.DisplayMember : Manufacturer || null,
      ModelNumber,
      fldPartNumber: isEmpty(fldPartNumber) ? null : fldPartNumber.toUpperCase(),
      Department: isString(Department)
        ? Department
        : Department?.fldMember || null,
      ProductDescription,
      fldSMS,
      fldConsumable,
      Bonded,
      ReOrderLevel: parseInt(ReOrderLevel) || null,
      Qty: QtyValue,
      fldOrderQty: fldOrderQtyValue,
      fldFactor:
        (QtyValue === fldOrderQtyValue
          ? 1.0
          : parseFloat(fldFactor)) || 1.0,
      ReOrderAmt: parseInt(ReOrderAmt) || null,
      SearchMaker:isNil(Manufacturer) ? null: typeof Manufacturer === 'object' ? removeNonAlphanumericChars(Manufacturer?.DisplayMember) : removeNonAlphanumericChars(Manufacturer) || null,
      SearchMod: isNil(ModelNumber)? null: removeNonAlphanumericChars(ModelNumber),
      SearchPart: isNil(fldPartNumber) ? null: removeNonAlphanumericChars(fldPartNumber) || null,
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (ProductID)
      ProductID: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
      updatedAt: new Date().toISOString(),
    } as any;

    try {
      setoldInvUndo([]);
      const res = await db.collections.inventory.upsert(document);
      onSave(res, isCreate);
      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = () => {
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  }

  const handleStorageUpdate = (isUpdated: boolean) => {
    if (isUpdated) {
      storageUpdate();
    }
  }

  const handleCancelUndo = () => {
    if (isCreate) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar) && !recordReadOnly) return;
    if (isEditing && !recordReadOnly) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  const handleAddBarCodePress = () => {
    setAddPopupVisible(true);
  };

  const handleDeleteBarCodePress = () => {
    setRemovePopupVisible(true);
  };

  const handlePrintPress = () => {
    setPrintPopupVisible(true)
  };

  const handleAddInvToOrderItems = () => {
    setAddOrderItemPopupVisible(true)
  };

  const handlePopupCancel = () => {
    setAddPopupVisible(false);
    setRemovePopupVisible(false);
    setPrintPopupVisible(false);
    handleClose();
  };

  const handleBarCodeSave = async (data: any) => {
    const db = await getDatabase();

    const { fldTritonBC, fldDefault } = data;

    const document = {
      fldTritonBC: fldTritonBC.toUpperCase(),
      fldDefault: false,
      ProductID: initialValue.ProductID,
      id: `${initialValue.ProductID}--${fldTritonBC}`,
    } as any;

    try {
      await db.invbarcodes.insert(document);
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Bar Code added success!',
      });

      setAddPopupVisible(false);
      reset();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: 'Invalid bar code!',
      });
    }
  };

  const handleBarCodeDelete = async (item: InvBarCodesDocument) => {
    try {
      await item.remove();
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Bar Code removed success!',
      });
      setRemovePopupVisible(false);
      reset();
    } catch (e: any) {
      console.log(e.message);
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const handleBarCodeRemove = async (data: any) => {
    const db = await getDatabase();
    const { fldTritonBC } = data;

    const item = await db.invbarcodes
      .findOne({
        selector: {
          ProductID: initialValue.ProductID,
          fldTritonBC: fldTritonBC.toUpperCase(),
        },
      })
      .exec();

    if (item) {
      handleBarCodeDelete(item);
    } else {
      console.log('Error: Barcode not found!')
    }
  };

  const handleAddOrderItemSave = (successMessage: string) => {
    setAddOrderItemPopupVisible(false);
    setSnackbar({
      open: true,
      message: successMessage,
      type: 'success',
    });
  };

  const handleAddOrderItemCancel = () => {
    setAddOrderItemPopupVisible(false);
  };

  const actions = [
    {
      icon: (
        <Icon>
          <img src={IconPrintBarCode} alt="" />
        </Icon>
      ),
      name: 'Print',
      onclick: handlePrintPress,
    },
    {
      icon: (
        <Icon>
          <img src={IconAddBarCode} alt="" />
        </Icon>
      ),
      name: 'Add',
      onclick: handleAddBarCodePress,
    },
    {
      icon: (
        <Icon>
          <img src={IconRemoveBarCode} alt="" />
        </Icon>
      ),
      name: 'Remove',
      onclick: handleDeleteBarCodePress,
    },
    {
      icon: (
        <Icon>
          <img src={CartIcon} alt="" />
        </Icon>
      ),
      name: 'Add to PO',
      onclick: handleAddInvToOrderItems,
    },
  ];

  if (isNil(invItem)) return null;

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreate;
  
  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const formClass = type === 'Dialog'
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <form
      id="Inventory-Edit-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            {(moduleReadOnly || recordReadOnly) && (
              <RecordEditWarningCard />
            )}
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Product Name',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength: INVENTORY.ProductName }}
                warning={(value) => handleCharLimitWarning(value, INVENTORY.ProductName)}
                control={control}
                name="ProductName"
              />
            </div>

            <div className="mt-3">
              <CompaniesDropdownOld
                control={control}
                label="Manufacturer"
                name="Manufacturer"
                onChange={onChange}
                variant="standard"
                size="small"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: 'Part Number',
                  variant: 'standard',
                }}
                rules={{ maxLength: INVENTORY.fldPartnumber }}
                warning={(value) => handleCharLimitWarning(value, INVENTORY.fldPartnumber)}
                defaultValue={initialValue.ModelNumber}
                control={control}
                name="fldPartNumber"
              />
            </div>
          </div>

          <div className="mt-3">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <InvGenSummaryForm
                      watch={watch}
                      control={control}
                      initialValue={initialValue}
                      form={invItem}
                      onChange={onChange}
                      getValues={getValues}
                      onStorageUpdate={handleStorageUpdate}
                      disableEdit={recordReadOnly}
                      isCreated={isCreate}
                      selectedItemOrderID={selectedItemOrderID}
                      onSave={onSave}
                    />
                  ),
                },
                {
                  // label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                  label: `Attachments`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.ProductID}
                      recordType={RecordType.InventoryGeneral}
                      recordTypeName={'InventoryGeneral'} // when refactor to .net api, add proper object.__typename for counts cache update
                      attachmentType={AttachmentType.Document}
                      categoryId={initialValue.fldSRHKey}
                      setAttachmentsCount={setDocumentsCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  // label: `Photos (${photosCount})`,
                  label: `Photos`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.ProductID}
                      recordType={RecordType.InventoryGeneral}
                      recordTypeName={'InventoryGeneral'} // when refactor to .net api, add proper object.__typename for counts cache update
                      attachmentType={AttachmentType.Photo}
                      categoryId={initialValue.fldSRHKey}
                      setAttachmentsCount={setPhotosCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  label: `Order History (${orderHistoryCount})`,
                  component: (
                    <InvOrderHistory
                      keyValue={initialValue.ProductID}
                    />
                  ),
                },
                {
                  label: `Spare for Equipment (${spareCount})`,
                  component: (
                    <InvSpares 
                      keyValue={initialValue.ProductID}
                      disableEdit={recordReadOnly}
                    />
                  ),
                },
                {
                  // label: `Comments (${commentsCount})`,
                  label: `Comments`,
                  disabled: isCreate,
                  component: (
                    <Comments
                      recordId={initialValue.ProductID}
                      recordType={RecordType.InventoryGeneral}
                      recordTypeName={'InventoryGeneral'} // when refactor to .net api, add proper object.__typename for counts cache update
                      setCommentsCount={setCommentsCount}
                      readOnly={moduleReadOnly || recordReadOnly}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      {type !== 'Dialog' && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? 'Save' : 'Close'}
          onOk={() => handleOk(isEditing)}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
          disabled={(moduleReadOnly || recordReadOnly) && isEditing}
          sx={{	
            position: "sticky",	
            bottom: 0,	
          }}
        >
          {!isCreate && !recordReadOnly && !moduleReadOnly && !isNil(invItem.ProductID) && (
            <Box sx={{ position: 'relative', height: 70 }}>
              <Backdrop open={open} />
              <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', bottom: 12, right: 12 }}
                FabProps={{ size: 'small' }}
                icon={(
                  <SpeedDialIcon
                    sx={{ fontSize: 'small' }}
                    openIcon={<CloseIcon />}
                  />
                )}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    classes={classes}
                    onClick={action.onclick}
                  />
                ))}
              </SpeedDial>
            </Box>
          )}

          {!isCreate && !moduleReadOnly && !recordReadOnly && !isNil(invItem.ProductID) && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete item"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <BarcodePopup
          initialValue={initialValue}
          onCancel={handlePopupCancel}
          visible={printPopupVisible}
          saveLoading={false}
          componentName='General Inventory'
      />

      <BarCode
        visible={addPopupVisible}
        onCancel={handlePopupCancel}
        onSave={handleBarCodeSave}
        inventory={initialValue}
        type={BarcodeActionType.Add}
      />

      <BarCode
        visible={removePopupVisible}
        onCancel={handlePopupCancel}
        onSave={handleBarCodeRemove}
        inventory={initialValue}
        type={BarcodeActionType.Remove}
      />

      <InvOrderItemsAddDialog
        visible={addOrderItemPopupVisible}
        initialValue={initialValue}
        onCancel={handleAddOrderItemCancel}
        onSave={handleAddOrderItemSave}
      />

      <Portal>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Portal>
    </form>
  );
};

export default InventoryDetailForm;
